import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { AuthenService } from '../../../../../admin/pages/authentication/service/authen.service'
import { AuthService } from '../../../../../admin/pages/authentication/service/auth.service'
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
  notif:any[]=[];
  constructor(private auth : AuthenService, public router: Router, public api:AuthService) { }
  user:any ;
  ngOnInit() { 
    this.user = JSON.parse(localStorage.getItem("user"));
    
  }

  logout(){
    this.auth.logout();
    const idToken = JSON.parse(localStorage.getItem("token"));
    if (!idToken) {
      this.router.navigate(['/auth/signin']);
  }
  }
 
}
