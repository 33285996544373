import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'Menu',
    title: 'MENU ',
    type: 'group',
    
    children: [
      {
        id: 'home',
        title: 'Home',
        type: 'item',
        url: '/home',
        icon: 'feather icon-home'
      },
      {
        id: 'feedback-page',
        title: 'Feedback',
        type: 'item',
        url: '/feedback-page',
        icon: 'feather icon-airplay'
      },
    
      {
        id: 'Analytics',
        title: 'Analytics',
        type: 'item',
        url: '/analytics',
        icon: 'feather icon-activity'
      },
      
    ]
  }
 
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
