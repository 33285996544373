import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchtable'
})
export class SearchtablePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(!value) return null;
    if(!args) return value;

    // args = args.toLowercase();
    // debugger;
    
    return value.filter(function(item){
      console.log(item);
      return JSON.stringify(item.DCnumber).includes(args);
    })
  }

}
