import { Injectable } from '@angular/core';
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class AuthenService {

  constructor() { }

  setLocalStorage(responseObj) {

    // Adds the expiration time defined on the JWT to the current moment
    const expiresAt = moment().add(responseObj.expiresIn);
    
    localStorage.setItem("user",JSON.stringify(responseObj.user));
    localStorage.setItem('token', responseObj.token);
    localStorage.setItem("expires", JSON.stringify(expiresAt.valueOf()) );
}  
logout() {
  localStorage.removeItem("token");
  localStorage.removeItem("expires");
  localStorage.removeItem("user");
}

public isLoggedIn() {
  return moment().isBefore(this.getExpiration());
}

isLoggedOut() {
  return !this.isLoggedIn();
}

getExpiration() {
  const expiration = localStorage.getItem("expires");
  const expiresAt = JSON.parse(expiration);
  return moment(expiresAt);
}  
}
