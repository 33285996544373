import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.loginchech();
  }
  loginchech(){
    const idToken = localStorage.getItem("token");
    if (idToken) {
      this.router.navigate(['/home']);
  }
  else {
       
      this.router.navigate(['/auth/signin']);
  }
  
  }
}
