import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders ,  HttpErrorResponse } from '@angular/common/http';
import { retry, tap, catchError  } from 'rxjs/operators';
import {  throwError   } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = false;
  token:any;
  admin_api  ="https://feedback.whocame.in:4010";
 
  constructor(
    private  http:HttpClient
  ) { }
  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  login(email: String, password: String) {
    return this.http.post(this.admin_api + '/admin/login',
      {email: email, password: password}
    ).pipe(
      tap(token => {
        
        return token;
      }),
    );
  }

  passwordchange(data) {
    return this.http.post(this.admin_api + '/api/users/update',data
      
    ).pipe(
      tap(token => {
        
        return token;
      }),
    );
  }
  createsubadmin(data) {
    return this.http.post(this.admin_api + '/api/users/createsubadmin',data
      
    ).pipe(
      tap(token => {
        
        return token;
      }),
    );
  }
  

  updatesubadmin(data) {
    return this.http.post(this.admin_api + '/api/users/updatesubadmin',data
      
    ).pipe(
      tap(token => {
        
        return token;
      }),
    );
  }
  delSubAdmin(id){
    return this.http.delete(this.admin_api+'/api/users/del/'+id).pipe(
      tap(data => {
        console.log(data);
        
      }),
    )
   

  }
  getsubadmin(){
    return this.http.get<[]>(this.admin_api+"/api/users/getsubadmin/",{  observe: "response"}).pipe(retry(3),catchError(this.handleError));

   }

   createcompany(data,file) {
    const fd = new FormData();
    fd.append('logo',file,file.name)
    fd.append("harry",JSON.stringify(data));
    return this.http.post(this.admin_api + '/api/company/create',fd,data
      
    ).pipe(
      tap(token => {
        
        return token;
      }),
    );
  }

  
  getcompany(){
    return this.http.get<[]>(this.admin_api+"/api/company/all",{  observe: "response"}).pipe(retry(3),catchError(this.handleError));

   }
   updatecompany(data) {
    return this.http.post(this.admin_api + '/api/company/updatecompany',data
      
    ).pipe(
      tap(token => {
        
        return token;
      }),
    );
  }
  notification(){
    return this.http.get<[]>(this.admin_api+"/api/dc/notification",{  observe: "response"}).pipe(retry(3),catchError(this.handleError));

   }
   makedafault(id){
    return this.http.get(this.admin_api+'/api/company/makedafault?id='+id).pipe(
      tap(data => {
        console.log(data);
        
      }),
    )
   

  }
}
